import React, {
  useState,
  useEffect
} from 'react';

import {
  BaseAPI,
  APIError,
} from './BaseAPI';

class CustomizerDataAPI extends BaseAPI {
  path = 'customizer-data/'
}

class DesignDataAPI extends BaseAPI {
  path = 'design/save/'
}

let customizerAPI: CustomizerDataAPI;
function getCustomizerAPI() {
  if (!customizerAPI) customizerAPI = new CustomizerDataAPI();
  return customizerAPI;
}

let designAPI: DesignDataAPI;
function getDesignAPI() {
  if (!designAPI) designAPI = new DesignDataAPI();
  return designAPI;
}

function useCustomizerData(path=''): [
  CustomizerData|undefined,
  boolean,
  APIError|undefined,
  (data: JerseyDesign) => Promise<any[]>,
  boolean,
] {
  const [customizerData, setCustomizerData] = useState<CustomizerData|undefined>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(<undefined|APIError> undefined);
  const [saveDesignLoading, setSaveDesignLoading] = useState(false);

  const loadData = async () => {
    setLoading(true);
    const api = getCustomizerAPI();
    try {
      const data = await api.get(path);
      setCustomizerData(data as CustomizerData);
      setError(undefined);
    } catch (error) {
      setError(error as APIError);
    }

    setLoading(false);
  }

  const saveDesign = async (data: JerseyDesign) => {
    let success = false;
    setSaveDesignLoading(true);
    let formData = new FormData();
    if (data.lace) formData.append('lace', data.lace);
    if (data.logo) formData.append('logo', data.logo);
    if (data.logo_left_shoulder) formData.append('logo_left_shoulder', data.logo_left_shoulder);
    if (data.logo_right_shoulder) formData.append('logo_right_shoulder', data.logo_right_shoulder);
    if (data.rendered_image) formData.append('rendered_image', data.rendered_image, 'render.jpg');
    formData.append('team_name', data.team_name);
    formData.append('notes', data.notes);
    formData.append('player_name', data.player_name);
    formData.append('player_number', data.player_number);
    formData.append('template', data.template);
    formData.append('color_1', data.color_1);
    formData.append('color_2', data.color_2);
    formData.append('color_3', data.color_3);
    formData.append('color_4', data.color_4);
    formData.append('color_5', data.color_5);
    formData.append('player_name_font', data.player_name_font);
    formData.append('player_number_font', data.player_number_font);
    formData.append('text_color', data.text_color);
    formData.append('text_color_outline', data.text_color_outline);

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('gclid')) {
      formData.append('gclid', urlParams.get('gclid') as string);
    }

    formData.append('customer_name', data.customer_name);
    formData.append('customer_email', data.customer_email);
    if (data.customer_add_to_list) {
      formData.append('customer_add_to_list', data.customer_add_to_list ? 'true' : 'false');
    }

    if (data.extra_recipients) {
      formData.append(`extra_recipient_email_count`, `${data.extra_recipients.length}`);
      data.extra_recipients.forEach((recipient: any, i: number) => {
        formData.append(`extra_recipient_email_${i}`, recipient);
      })
    }

    const api = getDesignAPI();
    try {
      const [result, response] = await api.post(formData, path);
      if (result && result.success) {
        success = true;
      }
      setSaveDesignLoading(false);
      return [success, result, undefined];
    } catch (error) {
      setSaveDesignLoading(false);
      return [false, {}, error];
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return [
    customizerData,
    loading,
    error,
    saveDesign,
    saveDesignLoading,
  ];
}

export {
  getCustomizerAPI,
  getDesignAPI,
  useCustomizerData,
};
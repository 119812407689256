import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Breadcrumbs,
  Link,
  Typography,
  Button,
  IconButton,
  Grow,
  CircularProgress,
  Collapse,
  Menu,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';

import renderJerseyToCanvas from '../utils/renderJerseyToCanvas';
import renderTracksuitToCanvasV1 from '../utils/renderTracksuitToCanvasV1';
import renderJerseyToCanvasV2 from '../utils/renderJerseyToCanvasV2';
import renderHockeyBagToCanvasV1 from '../utils/renderHockeyBagToCanvasV1';


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  renderedImageContainer: {
    paddingTop: 40,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  renderedImage: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    border: 'none',
  },
  loadingIndicator: {
    position: 'absolute',
    top: 20,
    right: 20,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  breadcrumbsContainer: {
    position: 'absolute',
    top: 0,
    left: 20,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  panelSelectorContainer: {
    marginLeft: 20,
    marginBottom: 20,
    width: 260,
  },
}));


const StyledCircularProgress = withStyles((theme) => ({
  root: {
    color: (window as any).APP_CONFIG ? (window as any).APP_CONFIG.siteColor1 : '#fd6422',
  },
}))(CircularProgress);


function RenderJersey({siteSetting, design, topBannerShown}: {
  siteSetting: SiteSetting;
  design: JerseyDesign;
  topBannerShown: boolean;
}) {
  const classes = useStyles();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [isRendering, setIsRendering] = useState(true);
  const [showMenu, setShowMenu] = useState<null|HTMLElement>(null);
  const heightOffset = topBannerShown ? 200 : 200 - 44;
  const [renderedImage, setRenderedImage] = useState('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=');
  const renderedImageHeight = (height - heightOffset) < 260 ? 260 : (height - heightOffset);
  const renderJerseyTimeoutHandle = useRef(null as NodeJS.Timeout|null);

  useEffect(() => {
    if (renderJerseyTimeoutHandle.current) {
      clearTimeout(renderJerseyTimeoutHandle.current);
      renderJerseyTimeoutHandle.current = null;
      console.log('render was already scheduled');
    }
    renderJerseyTimeoutHandle.current = setTimeout(async () => {
      // console.log('user design data updated', design);
      renderJerseyTimeoutHandle.current = null;
      setIsRendering(true);
      if (design.template_data.renderer === 'v1') {
        const renderedJersey = await renderJerseyToCanvas(design, {
          main: 'design_generator',
          part1: 'design_generator1_main',
          part2: 'design_generator2_main',
          part3: 'design_generator3_main',
        }, siteSetting);
  
        setRenderedImage(renderedJersey);
      }

      if (design.template_data.renderer === 'v2') {
        const renderedJersey = await renderJerseyToCanvasV2(design, {
          main: 'design_generator',
          part1: 'design_generator1_main',
          part2: 'design_generator2_main',
          part3: 'design_generator3_main',
          part4: 'design_generator4_main',
        }, siteSetting);
  
        setRenderedImage(renderedJersey);
      }

      if (design.template_data.renderer === 'tracksuit-v1') {
        const renderedJersey = await renderTracksuitToCanvasV1(design, {
          main: 'design_generator',
          part1: 'design_generator1_main',
          part2: 'design_generator2_main',
          part3: 'design_generator3_main',
        }, siteSetting);
  
        setRenderedImage(renderedJersey);
      }

      if (design.template_data.renderer === 'hockeybag-v1') {
        const renderedJersey = await renderHockeyBagToCanvasV1(design, {
          main: 'design_generator',
          part1: 'design_generator1_main',
          part2: 'design_generator2_main',
          part3: 'design_generator3_main',
        }, siteSetting);
  
        setRenderedImage(renderedJersey);
      }

      setIsRendering(false);
    }, 100);
  }, [design]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    const iosOrientationChange = () => {
      window.addEventListener('resize', handleResize);
      setTimeout(() => {
        window.removeEventListener('resize', handleResize);
      }, 300);
    }
    
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    // on safari mobile, only resize on orientation change
    if (iOSSafari) {
      window.addEventListener('orientationchange', iosOrientationChange);
    }
    else {
      window.addEventListener('resize', handleResize);
    }
    
    return () => {
      window.removeEventListener('resize', handleResize);
      window.addEventListener('orientationchange', iosOrientationChange);
    }
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.breadcrumbsContainer}>
        <Grow in={(siteSetting && siteSetting.breadcrumbs && (siteSetting.breadcrumbs.contents.length > 1))}>
          <div>
            <div style={{display: 'inline-block'}}>
              <IconButton
                onClick={(e) => {
                  setShowMenu(e.currentTarget);
                }}
              >
                <MenuIcon />
              </IconButton>
            </div>
          {siteSetting.breadcrumbs.contents && siteSetting.breadcrumbs.contents.map((item: any, i: number) => {
            return (
              <div style={{display: 'inline-block'}}>
                <Button
                  size="small"
                  onClick={() => {
                    if (item.link) window.location = item.link;
                  }}
                >{item.title}</Button>
                {(i < (siteSetting.breadcrumbs.contents.length - 1)) &&
                  <ChevronRightIcon fontSize="small" style={{verticalAlign: 'middle'}} />
                }
              </div>
            )
          })}
          </div>
        </Grow>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={showMenu}
        keepMounted
        open={Boolean(showMenu)}
        onClose={() => setShowMenu(null)}
      >
        {siteSetting.categories.map((item: any, i: number) => 
          <MenuItem key={`menu-item-${i}`} onClick={() => {
            window.location.href = `/${item.slug}/`;
            setShowMenu(null);
          }}>
            {item.name_plural}
          </MenuItem>
        )}
      </Menu>
      <div className={classes.loadingIndicator}>
        {isRendering && <StyledCircularProgress color="secondary"/>}
      </div>
      <div className={classes.renderedImageContainer} style={{width, height: renderedImageHeight}}>
        <img src={renderedImage} alt="" className={classes.renderedImage} />
      </div>
    </div>
  );
}

export default RenderJersey;
import React, {
  useState,
  useEffect,
  useRef
} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Breadcrumbs,
  Link,
  Typography,
  Button,
  ButtonBase,
  IconButton,
  Collapse,
  Slide,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControl,
  TextField,
  Tooltip,
  Grid,
  Hidden,
  Select,
  MenuItem,
  InputLabel,
  Tabs,
  Tab,
  Slider,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ReplayIcon from '@material-ui/icons/Replay';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

import Dropzone from 'react-dropzone'


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  panelSwitcherParentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  panelToggleContainer: {
    marginRight: 20,
    marginBottom: 20,
  },
  panelSwitcherContainer: {
    minWidth: 260,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 20,
    marginBottom: 20,
  },
  panelSwitcherButtonsContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  panelSwitcherSelectedContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'left',
  },
  panelSwitcherSelectedIndexContainer: {
    fontSize: '.75rem',
  },
  panelSwitcherSelectedPanelNameContainer: {
    textTransform: 'uppercase',
  },
  panelSelectorContainer: {
    marginLeft: 20,
    marginBottom: 20,
    width: 260,
  },
  displayBlock: {
    display: 'block',
  },
  panelContainer: {

  },
  teamNameContainer: {
    height: 64,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  teamNameFormControl: {
    margin: 20,
    marginBottom: 35,
  },
  patternContainer: {
    "-webkitBackdropFilter": 'blur(8px)',
    backdropFilter: 'blur(8px)',
    maxHeight: 300,
    overflowY: 'auto',
  },
  singleColorsContainer: {
    backgroundColor: '#ffffff',
  },
  colorsContainer: {
    backgroundColor: '#ffffff',
  },
  colorsContainerSelectorParent: {
    borderTop: "solid 1px rgb(238,238,238)",
  },
  colorsTabSelector: {
    marginBottom: 10,
  },
  logoContainer: {
    
  },
  uploadContainer: {
    padding: 10,
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 100,
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  selectedLogoImageContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 160,
  },
  textPanelContainer: {
  },
  panelPadding: {
    padding: 20,
  },
  textPanelFormControl: {
    margin: 10,
  },
  textMultiPanelFormControl: {
    margin: 10,
    width: 'calc(100% - 20px)',
  },
  panelLabel: {
    backgroundColor: '#ffffff',
  },
  panelItemSelectedDimImage: {
    opacity: 0.7
  },
  panelItemSelectedIcon: {
    position: 'absolute',
  }
}));

function TeamNamePanel({customizer, design, onDesignChanged}: any) {
  const classes = useStyles();
  const [value, setValue] = useState(design.team_name ? design.team_name : '');
  const teamNamePanelTimeoutHandle = useRef(null as NodeJS.Timeout|null);
  
  useEffect(() => {
    if (teamNamePanelTimeoutHandle.current) {
      clearTimeout(teamNamePanelTimeoutHandle.current);
      teamNamePanelTimeoutHandle.current = null;
    }
    teamNamePanelTimeoutHandle.current = setTimeout(() => {
      if (design.team_name != value) {
        let designClone = Object.assign({}, design);
        designClone.team_name = value;
        onDesignChanged(designClone);
      }
    }, 1000);
  }, [value]);
  
  return (
    <div className={classes.teamNameContainer}>
      <FormControl fullWidth variant="outlined" className={classes.teamNameFormControl}>
        <TextField
          id="team-name"
          label="Team Name"
          variant="outlined"
          autoFocus
          value={value}
          onChange={(e: any) => setValue(e.target.value.substring(0, 255))}
        />
      </FormControl>
    </div>
  );
}


function PatternPanel({customizer, design, onDesignChanged}: {
  customizer: CustomizerData;
  design: JerseyDesign;
  onDesignChanged: (design: JerseyDesign) => void;
}) {
  const classes = useStyles();
  const [value, setValue] = useState(design.template);
  const patternPanelTimeoutHandle = useRef(null as NodeJS.Timeout|null);

  useEffect(() => {
    if (patternPanelTimeoutHandle.current) {
      clearTimeout(patternPanelTimeoutHandle.current);
      patternPanelTimeoutHandle.current = null;
    }

    patternPanelTimeoutHandle.current = setTimeout(() => {
      if (design.template != value) {
        let designClone = Object.assign({}, design);
        designClone.template = value;

        customizer.templates.filter((template) => template.id === value).forEach((templateData) => {
          designClone.template_data = templateData;

          if (templateData.force_default_color) {
            designClone.color_1 = templateData.default_color_1;
            designClone.color_2 = templateData.default_color_2;
            designClone.color_3 = templateData.default_color_3;
            designClone.color_4 = templateData.default_color_4;
            designClone.color_5 = templateData.default_color_5;

            designClone.text_color = templateData.text_color;
            designClone.text_color_outline = templateData.text_stroke_color;

            designClone.player_name_font_id = templateData.player_name_font_id;
            designClone.player_number_font_id = templateData.player_number_font_id;
            designClone.player_name_font_name = templateData.player_name_font_name;
            designClone.player_number_font_name = templateData.player_number_font_name;
          }

          designClone.number_of_logos = templateData.number_of_logos;
          designClone.label_logo_1 = templateData.label_logo_1;
          designClone.label_logo_2 = templateData.label_logo_2;
          designClone.label_logo_3 = templateData.label_logo_3;
          designClone.label_laces = templateData.label_laces;
          designClone.show_player_name_field = templateData.show_player_name_field;
          designClone.show_player_number_field = templateData.show_player_number_field;

          if (!design.logo_file) designClone.logo_url = templateData.logo;
          if (!design.logo_left_shoulder_file) designClone.logo_left_shoulder_url = templateData.logo_left_shoulder;
          if (!design.logo_right_shoulder_file) designClone.logo_right_shoulder_url = templateData.logo_right_shoulder;

          if (!design.logo_position_dirty) {
            designClone.logo_position = JSON.parse(JSON.stringify(templateData.logo_position));
          }

          if (!design.logo_left_shoulder_position_dirty) {
            designClone.logo_left_shoulder_front_position = JSON.parse(JSON.stringify(templateData.logo_left_shoulder_front_position));
            designClone.logo_left_shoulder_back_position = JSON.parse(JSON.stringify(templateData.logo_left_shoulder_back_position));
          }

          if (!design.logo_right_shoulder_position_dirty) {
            designClone.logo_right_shoulder_front_position = JSON.parse(JSON.stringify(templateData.logo_right_shoulder_front_position));
            designClone.logo_right_shoulder_back_position = JSON.parse(JSON.stringify(templateData.logo_right_shoulder_back_position));
          }
          designClone.logo_extra_1_url = templateData.logo_extra_1;
          designClone.logo_extra_1_position = JSON.parse(JSON.stringify(templateData.logo_extra_1_position));
          designClone.logo_extra_2_url = templateData.logo_extra_2;
          designClone.logo_extra_2_position = JSON.parse(JSON.stringify(templateData.logo_extra_2_position));
          onDesignChanged(designClone);
        });
      }
    }, 0);
  }, [value]);
  
  if (!customizer) return null;
  return (
    <div className={classes.patternContainer}>
      {customizer.templates.map((template, i) => {
        return (
          <Tooltip key={`tooltip-pattern-${i}`} title={template.name}>
            <ButtonBase onClick={() => {
              setValue(template.id);
            }}>
              <img
                src={template.thumbnail}
                width="64"
                height="64"
                alt={template.name}
                className={(design.template == template.id) ? classes.panelItemSelectedDimImage : ''}
              />
              {(design.template == template.id) &&
                <CheckIcon color="secondary" fontSize="large" className={classes.panelItemSelectedIcon} />
              }
            </ButtonBase>
          </Tooltip>
        );
      })}
    </div>
  );
}


function LacesPanel({customizer, design, onDesignChanged}: any) {
  const classes = useStyles();
  const [value, setValue] = useState(design.template);
  const patternPanelTimeoutHandle = useRef(null as NodeJS.Timeout|null);

  useEffect(() => {
    if (patternPanelTimeoutHandle.current) {
      clearTimeout(patternPanelTimeoutHandle.current);
      patternPanelTimeoutHandle.current = null;
    }

    patternPanelTimeoutHandle.current = setTimeout(() => {
      if (design.template != value) {
        let designClone = Object.assign({}, design);
        designClone.lace = value;

        onDesignChanged(designClone);
      }
    }, 0);
  }, [value]);
  
  if (!customizer) return null;
  return (
    <div className={classes.patternContainer}>
      <Tooltip title="None">
        <ButtonBase onClick={() => {
          setValue(null);
        }}>
          <div style={{width: 64, height: 64}}></div>
          {(!design.lace) &&
            <NotInterestedIcon color="secondary" fontSize="large" className={classes.panelItemSelectedIcon} />
          }
          {(design.lace) &&
            <NotInterestedIcon fontSize="large" className={classes.panelItemSelectedIcon} />
          }
        </ButtonBase>
      </Tooltip>
      {design.template_data.laces.map((lace: any, i: string) => {
        return (
          <Tooltip key={`tooltip-pattern-${i}`} title={lace.name}>
            <ButtonBase onClick={() => {
              setValue(lace.id);
            }}>
              <img
                src={lace.thumbnail}
                width="64"
                height="64"
                alt={lace.name}
                className={(design.template == lace.id) ? classes.panelItemSelectedDimImage : ''}
              />
              {(design.lace == lace.id) &&
                <CheckIcon color="secondary" fontSize="large" className={classes.panelItemSelectedIcon} />
              }
            </ButtonBase>
          </Tooltip>
        );
      })}
    </div>
  );
}


function SingleColorsPanel({customizer, color, onColorChange}: any) {
  const classes = useStyles();
  const [value, setValue] = useState(color);

  if (!customizer) return null;
  return (
    <div className={classes.singleColorsContainer}>
      {customizer.colors.map((item: any, i: string) => {
        return (
          <Tooltip title={item.name} key={`color-${i}`}>
            <ButtonBase
              onClick={() => onColorChange(item.color)}
            >
              <div style={{
                width: '64px',
                height: '64px',
                backgroundColor: item.color,
              }}></div>
              {(item.color == color) &&
                <CheckIcon color="secondary" fontSize="large" className={classes.panelItemSelectedIcon} />
              }
            </ButtonBase>
          </Tooltip>
        );
      })}
    </div>
  );
}


function ColorsPanel({customizer, design, onDesignChanged}: any) {
  const classes = useStyles();
  const [field, setField] = useState('color_1');
  const [color1, setColor1] = useState(design.color_1);
  const [color2, setColor2] = useState(design.color_2);
  const [color3, setColor3] = useState(design.color_3);
  const [color4, setColor4] = useState(design.color_4);
  const [color5, setColor5] = useState(design.color_5);
  const numberOfColor = design.template_data ? design.template_data.number_of_colors : 5;
  const colorsPanelTimeoutHandle = useRef(null as NodeJS.Timeout|null);

  useEffect(() => {
    if (colorsPanelTimeoutHandle.current) {
      clearTimeout(colorsPanelTimeoutHandle.current);
      colorsPanelTimeoutHandle.current = null;
    }

    colorsPanelTimeoutHandle.current = setTimeout(() => {
      let isDirty = false;
      if (design.color_1 != color1) isDirty = true;
      if (design.color_2 != color2) isDirty = true;
      if (design.color_3 != color3) isDirty = true;
      if (design.color_4 != color4) isDirty = true;
      if (design.color_5 != color5) isDirty = true;

      let designClone = Object.assign({}, design);
      designClone.color_1 = color1;
      designClone.color_2 = color2;
      designClone.color_3 = color3;
      designClone.color_4 = color4;
      designClone.color_5 = color5;
      if (isDirty) onDesignChanged(designClone);
    }, 0);
  }, [color1, color2, color3, color4, color5]);

  useEffect(() => {
    if (design.color_1 != color1) setColor1(design.color_1);
    if (design.color_2 != color2) setColor2(design.color_2);
    if (design.color_3 != color3) setColor3(design.color_3);
    if (design.color_4 != color4) setColor4(design.color_4);
    if (design.color_5 != color5) setColor5(design.color_5);
  }, [design]);
  
  if (!customizer) return null;
  return (
    <div className={classes.colorsContainer}>
      <Paper className={classes.colorsTabSelector} square>
        <Tabs
          value={field}
          onChange={(event: any, nextField: string) => {
            setField(nextField);
          }}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {(numberOfColor >=1) && <Tab label="Color 1" value="color_1" />}
          {(numberOfColor >=2) && <Tab label="Color 2" value="color_2" />}
          {(numberOfColor >=3) && <Tab label="Color 3" value="color_3" />}
          {(numberOfColor >=4) && <Tab label="Color 4" value="color_4" />}
          {(numberOfColor >=5) && <Tab label="Color 5" value="color_5" />}
        </Tabs>
      </Paper>
      <div className={classes.colorsContainerSelectorParent}>
        <Collapse
          in={field == 'color_1'}
        >
          <SingleColorsPanel
            customizer={customizer}
            color={color1}
            onColorChange={(color: string) => setColor1(color)}
          />
        </Collapse>
        <Collapse
          in={field == 'color_2'}
        >
          <SingleColorsPanel
            customizer={customizer}
            color={color2}
            onColorChange={(color: string) => setColor2(color)}
          />
        </Collapse>
        <Collapse
          in={field == 'color_3'}
        >
          <SingleColorsPanel
            customizer={customizer}
            color={color3}
            onColorChange={(color: string) => setColor3(color)}
          />
        </Collapse>
        <Collapse
          in={field == 'color_4'}
        >
          <SingleColorsPanel
            customizer={customizer}
            color={color4}
            onColorChange={(color: string) => setColor4(color)}
          />
        </Collapse>
        <Collapse
          in={field == 'color_5'}
        >
          <SingleColorsPanel
            customizer={customizer}
            color={color5}
            onColorChange={(color: string) => setColor5(color)}
          />
        </Collapse>
      </div>
    </div>
  );
}


function PositionSlider({
  value,
  setValue,
  step,
  min,
  max,
  leftIcon,
  rightIcon,
}: any) {
  const [minimum, setMinimum] = useState(min);
  const [maximum, setMaximum] = useState(max);
  if (!leftIcon) leftIcon = <RemoveIcon />;
  if (!rightIcon) rightIcon = <AddIcon />;
  return (
    <Grid container spacing={2} style={{marginTop: 0}}>
      <Grid item style={{display: 'flex', alignItems: 'center'}}>
        {leftIcon}
      </Grid>
      <Grid item xs>
        <Slider
          value={value}
          getAriaValueText={(value: number) => {
            return `${value}`;
          }}
          onChange={(event: any, value: number | number[]) => {
            setValue(event, value);
          }}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          step={step}
          marks
          min={minimum}
          max={maximum}          
        />
      </Grid>
      <Grid item style={{display: 'flex', alignItems: 'center'}}>
        {rightIcon}
      </Grid>
    </Grid>
  );
}


function SingleLogoPanel({
  customizer,
  design,
  onDesignChanged,
  logoField,
  positionFields,
  fileDropLabel,
}: any) {
  const classes = useStyles();
  const [selectedLogoUrl, setSelectedLogoUrl] = useState(design[logoField + '_url']);
  const [selectedFile, setSelectedFile] = useState({} as any);
  const [showUploader, setShowUploader] = useState(true);

  const dropLabel = fileDropLabel ? fileDropLabel : 'Click to upload or drag logo here (.png or .jpg)';

  useEffect(() => {
    if (design[logoField + '_url'] != selectedLogoUrl) setSelectedLogoUrl(design[logoField + '_url']);
  }, [design]);

  const logoPanelTimeoutHandle = useRef(null as NodeJS.Timeout|null);

  useEffect(() => {
    if (logoPanelTimeoutHandle.current) {
      clearTimeout(logoPanelTimeoutHandle.current);
      logoPanelTimeoutHandle.current = null;
    }
    logoPanelTimeoutHandle.current = setTimeout(() => {
      if ((design[logoField + '_url'] != selectedLogoUrl) || ((design[logoField + '_file'] != selectedFile) && Boolean(selectedFile.name))) {
        let designClone = Object.assign({}, design);
        designClone[logoField + '_url'] = selectedLogoUrl ? selectedLogoUrl : design.template_data[logoField];
        designClone[logoField + '_file'] = selectedFile;
        console.log(designClone)
        onDesignChanged(designClone);
      }
    }, 100);
  }, [selectedLogoUrl, selectedFile]);

  const removeLogo = () => {
    setSelectedFile({});
    setSelectedLogoUrl('');
    setShowUploader(true);
    let designClone = Object.assign({}, design);
    designClone[logoField + '_url'] = design.template_data[logoField];
    designClone[logoField + '_file'] = null;
    onDesignChanged(designClone);
  };

  
  if (!customizer) return null;
  return (
    <div className={classes.logoContainer}>
      <Collapse
        in={!showUploader}
      >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <div className={classes.selectedLogoImageContainer}>
            <img src={selectedLogoUrl} height="100" alt={selectedFile.name ? selectedFile.name : ''} />
            <div style={{display: 'flex'}}>
              <Button
                startIcon={<CloudUploadIcon />}
                onClick={() => setShowUploader(true)}
                style={{marginRight: 8}}
              >Change</Button>
              <Button
                startIcon={<CloseIcon />}
                onClick={removeLogo}
              >Remove</Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Grid container style={{paddingLeft: 8, paddingRight: 8}}>
            <Grid item xs={4} sm={3}>
              <Typography variant="body1" style={{marginTop: 8}}>Left / Right</Typography>
            </Grid>
            <Grid item xs={8} sm={9}>
              <PositionSlider
                key={`${logoField}-x-${design.template}-${design[positionFields.front].w}`}
                value={design[positionFields.front].x}
                setValue={(event: any, value: number | number[]) => {
                  let delta = design[positionFields.front].x - (value as number);
                  let designClone = Object.assign({}, design);
                  designClone[positionFields.front].x = value as number;
                  if (positionFields.back) {
                    designClone[positionFields.back].x = designClone[positionFields.back].x + delta;
                  }
                  designClone[logoField+'_dirty'] = true;
                  onDesignChanged(designClone);
                }}
                step={10}
                min={design[positionFields.front].x - 50}
                max={design[positionFields.front].x + 50}
                leftIcon={<Typography variant="body1">L</Typography>}
                rightIcon={<Typography variant="body1">R</Typography>}
              />
            </Grid>
          </Grid>

          <Grid container style={{paddingLeft: 8, paddingRight: 8}}>
            <Grid item xs={4} sm={3}>
              <Typography variant="body1" style={{marginTop: 8}}>Up / Down</Typography>
            </Grid>
            <Grid item xs={8} sm={9}>
              <PositionSlider
                key={`${logoField}-y-${design.template}-${design[positionFields.front].w}`}
                value={design[positionFields.front].y}
                setValue={(event: any, value: number | number[]) => {
                  let delta = design[positionFields.front].y - (value as number);
                  let designClone = Object.assign({}, design);
                  designClone[positionFields.front].y = value as number;
                  if (positionFields.back) {
                    designClone[positionFields.back].y = designClone[positionFields.back].y + delta;
                  }
                  designClone[logoField+'_dirty'] = true;
                  onDesignChanged(designClone);
                }}
                step={10}
                min={design[positionFields.front].y - 50}
                max={design[positionFields.front].y + 50}
                leftIcon={<ExpandMoreIcon />}
                rightIcon={<ExpandLessIcon />}
              />
            </Grid>
          </Grid>

          <Grid container style={{paddingLeft: 8, paddingRight: 8}}>
            <Grid item xs={4} sm={3}>
              <Typography variant="body1" style={{marginTop: 8}}>Size</Typography>
            </Grid>
            <Grid item xs={8} sm={9}>
              <PositionSlider
                key={`${logoField}-w-${design.template}`}
                value={design[positionFields.front].w}
                setValue={(event: any, value: number | number[]) => {
                  let delta = design[positionFields.front].w - (value as number);
                  let designClone = Object.assign({}, design);
                  designClone[positionFields.front].w = value as number;
                  designClone[positionFields.front].x += delta / 2;
                  designClone[positionFields.front].y += delta / 2;
                  if (positionFields.back) {
                    designClone[positionFields.back].w = designClone[positionFields.back].w - delta;
                  }
                  designClone[logoField+'_dirty'] = true;
                  onDesignChanged(designClone);
                }}
                step={10}
                min={design.template_data[positionFields.front].w / 3}
                max={design.template_data[positionFields.front].w * 2}
              />
            </Grid>
          </Grid>
          
        </Grid>
        <Grid item xs={12} sm={2} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Button
            startIcon={<ReplayIcon />}
            onClick={() => {
              if (customizer && customizer.templates && customizer.templates.length) {
                customizer.templates.forEach((template: any) => {
                  if (template.id === design.template) {
                    let designClone = Object.assign({}, design);
                    designClone[positionFields.front].w = template[positionFields.front].w;
                    designClone[positionFields.front].x = template[positionFields.front].x;
                    designClone[positionFields.front].y = template[positionFields.front].y;
                    if (positionFields.back) {
                      designClone[positionFields.back].w = template[positionFields.back].w;
                      designClone[positionFields.back].x = template[positionFields.back].x;
                      designClone[positionFields.back].y = template[positionFields.back].y;
                    }
                    designClone[logoField+'_dirty'] = false;
                    onDesignChanged(designClone);
                  }
                });
              }
            }}
          >Reset</Button>
        </Grid>
      </Grid>
      </Collapse>
      <Collapse
        in={showUploader}
      >
      <Grid container spacing={3}>
        {(selectedFile.name && showUploader) &&
        <Grid item xs={12} sm={selectedFile.name ? 3 : 12}>
            <div className={classes.selectedLogoImageContainer}>
              <img src={selectedLogoUrl} height="100" alt={selectedFile.name ? selectedFile.name : ''} />
              <Button
                startIcon={<CloudUploadIcon />}
                onClick={() => setShowUploader(false)}
              >Cancel</Button>
            </div>
        </Grid>
        }
        <Grid item xs={12} sm={selectedFile.name ? 9 : 12}>
          <Dropzone
            accept=".jpeg,.jpg,.png"
            onDrop={(acceptedFiles: any[]) => {
              if (acceptedFiles.length > 0) {
                const file = acceptedFiles[0];

                const reader = new FileReader()
                reader.onload = () => {
                  const base64Url = reader.result;
                  if (base64Url) {
                    setSelectedLogoUrl(base64Url as string);
                  }
                };

                reader.readAsDataURL(file)
                setSelectedFile(file);
                setShowUploader(false);
              }
            }}
          >
            {({getRootProps, getInputProps}) => (
              <section className={classes.uploadContainer}>
                <div className={classes.dropzone} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <CloudUploadIcon fontSize="large" />
                  <Typography className={classes.textAlignCenter}>{dropLabel}</Typography>
                </div>
              </section>
            )}
          </Dropzone>
        </Grid>
      </Grid>
      </Collapse>
    </div>
  );
}


function LogoPanel({customizer, design, onDesignChanged}: any) {
  const classes = useStyles();
  const [field, setField] = useState('center');

  if (!customizer) return null;
  return (
    <div className={classes.colorsContainer}>
      <Paper className={classes.colorsTabSelector} square>
        <Tabs
          value={field}
          onChange={(event: any, nextField: string) => {
            setField(nextField);
          }}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {design.number_of_logos >= 1 && <Tab label={design.label_logo_1} value="center" />}
          {design.number_of_logos >= 2 && <Tab label={design.label_logo_2} value="left_shoulder" />}
          {design.number_of_logos >= 3 && <Tab label={design.label_logo_3} value="right_shoulder" />}
        </Tabs>
      </Paper>
      <div className={classes.colorsContainerSelectorParent}>
        <Collapse
          in={field == 'center'}
        >
          <SingleLogoPanel
            key={'logo-panel'}
            customizer={customizer}
            design={design}
            logoField={'logo'}
            positionFields={{
              front: 'logo_position',
              back: null
            }}
            onDesignChanged={onDesignChanged}
          />
        </Collapse>
        <Collapse
          in={field == 'left_shoulder'}
        >
          <SingleLogoPanel
            key={'logo-left-panel'}
            customizer={customizer}
            design={design}
            logoField={'logo_left_shoulder'}
            positionFields={{
              front: 'logo_left_shoulder_front_position',
              back: 'logo_left_shoulder_back_position'
            }}
            onDesignChanged={onDesignChanged}
          />
        </Collapse>
        <Collapse
          in={field == 'right_shoulder'}
        >
          <SingleLogoPanel
            key={'logo-right-panel'}
            customizer={customizer}
            design={design}
            logoField={'logo_right_shoulder'}
            positionFields={{
              front: 'logo_right_shoulder_front_position',
              back: 'logo_right_shoulder_back_position'
            }}
            onDesignChanged={onDesignChanged}
          />
        </Collapse>
      </div>
    </div>
  );
}



function TextPanel({customizer, design, onDesignChanged}: any) {
  const classes = useStyles();
  const [field, setField] = useState(design.show_player_name_field ? 'name' : 'number');

  const [playerName, setPlayerName] = useState(design.player_name ? design.player_name : '')
  const [playerNumber, setPlayerNumber] = useState(design.player_number ? design.player_number : '')
  const [color1, setColor1] = useState(design.text_color ? design.text_color : '')
  const [color2, setColor2] = useState(design.text_color_outline ? design.text_color_outline : '')
  const [playerNameFontId, setPlayerNameFontId] = useState(design.player_name_font_id ? design.player_name_font_id : null)
  const [playerNumberFontId, setPlayerNumberFontId] = useState(design.player_number_font_id ? design.player_number_font_id : null)
  const [playerNameFontName, setPlayerNameFontName] = useState(design.player_name_font_name ? design.player_name_font_name : null)
  const [playerNumberFontName, setPlayerNumberFontName] = useState(design.player_number_font_name ? design.player_number_font_name : null)

  const textPanelTimeoutHandle = useRef(null as NodeJS.Timeout|null);
  const teamNamePanelTimeoutHandle = useRef(null as NodeJS.Timeout|null);
  
  useEffect(() => {
    if (textPanelTimeoutHandle.current) {
      clearTimeout(textPanelTimeoutHandle.current);
      textPanelTimeoutHandle.current = null;
    }
    textPanelTimeoutHandle.current = setTimeout(() => {
      if ((design.player_name != playerName)
          || (design.player_number != playerNumber)
          || (design.player_name_font_id != playerNameFontId)
          || (design.player_number_font_id != playerNumberFontId)
          || (design.player_name_font_name != playerNameFontName)
          || (design.player_number_font_name != playerNumberFontName)
        ) {
        let designClone = Object.assign({}, design);
        designClone.player_name = playerName;
        designClone.player_number = playerNumber;
        designClone.player_name_font_id = playerNameFontId;
        designClone.player_number_font_id = playerNumberFontId;
        designClone.player_name_font_name = playerNameFontName;
        designClone.player_number_font_name = playerNumberFontName;
        onDesignChanged(designClone);
      }
    }, 1000);
  }, [playerName, playerNumber, playerNameFontId, playerNumberFontId, playerNameFontName, playerNumberFontName]);

  useEffect(() => {
    if (teamNamePanelTimeoutHandle.current) {
      clearTimeout(teamNamePanelTimeoutHandle.current);
      teamNamePanelTimeoutHandle.current = null;
    }
    teamNamePanelTimeoutHandle.current = setTimeout(() => {
      if ((design.text_color != color1) || (design.text_color_outline != color2)) {
        let designClone = Object.assign({}, design);
        designClone.text_color = color1;
        designClone.text_color_outline = color2;
        onDesignChanged(designClone);
      }
    }, 0);
  }, [color1, color2]);

  useEffect(() => {
    if (design.text_color != color1) setColor1(design.text_color);
    if (design.text_color_outline != color2) setColor2(design.text_color_outline);
    if (design.player_name_font_id != playerNameFontId) setPlayerNameFontId(design.player_name_font_id);
    if (design.player_name_font_name != playerNameFontName) setPlayerNameFontName(design.player_name_font_name);
    if (design.player_number_font_id != playerNumberFontId) setPlayerNumberFontId(design.player_number_font_id);
    if (design.player_number_font_name != playerNumberFontName) setPlayerNumberFontName(design.player_number_font_name);
  }, [design]);
  
  if (!customizer) return null;

  return (
    <div className={classes.textPanelContainer}>
      <Paper className={classes.colorsTabSelector} square>
        <Tabs
          value={field}
          onChange={(event: any, nextField: string) => {
            setField(nextField);
          }}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {!!design.show_player_name_field && <Tab label="Name" value="name" />}
          {!!design.show_player_number_field && <Tab label="Number" value="number" />}
          <Tab label="Color 1" value="color1" />
          <Tab label="Color 2" value="color2" />
        </Tabs>
      </Paper>
      <div>
        <Collapse
          in={field == 'name' && !!design.show_player_name_field}
        >
          <Grid container>
            <Grid item xs={8}>
              <FormControl fullWidth variant="outlined" className={classes.textMultiPanelFormControl}>
                <TextField
                  id="player-name"
                  label="Player Name"
                  variant="outlined"
                  autoFocus
                  value={playerName}
                  onChange={(e: any) => setPlayerName(e.target.value.substring(0, 255))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth variant="outlined" className={classes.textMultiPanelFormControl}>
                <InputLabel id="player-name-font-label">Font</InputLabel>
                <Select
                  labelId="player-name-font-label"
                  id="player-name-font"
                  value={playerNameFontId}
                  onChange={(e) => {
                    const fontId = e.target.value;
                    let fontName = playerNameFontName;

                    customizer.fonts.forEach((font: {id: string, name: string}) => {
                      if (font.id == fontId) {
                        fontName = font.name
                      }
                    });

                    setPlayerNameFontId(fontId);
                    setPlayerNameFontName(fontName);
                  }}
                  label="Font"
                >
                  {customizer.fonts.map((font: {id: string, name: string}) => <MenuItem key={`font-${font.id}`} value={font.id}>{font.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Collapse>
        <Collapse
          in={field == 'number' && !!design.show_player_number_field}
        >
          <Grid container>
            <Grid item xs={8}>
              <FormControl fullWidth variant="outlined" className={classes.textMultiPanelFormControl}>
                <TextField
                  id="player-number"
                  label="Player Number"
                  variant="outlined"
                  type="number"
                  value={playerNumber}
                  onChange={(e: any) => setPlayerNumber(e.target.value.substring(0, 255))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth variant="outlined" className={classes.textMultiPanelFormControl}>
                <InputLabel id="player-number-font-label">Font</InputLabel>
                <Select
                  labelId="player-number-font-label"
                  id="player-number-font"
                  value={playerNumberFontId}
                  onChange={(e) => {
                    const fontId = e.target.value;
                    let fontName = playerNumberFontName;

                    customizer.fonts.forEach((font: {id: string, name: string}) => {
                      if (font.id == fontId) {
                        fontName = font.name
                      }
                    });

                    setPlayerNumberFontId(fontId);
                    setPlayerNumberFontName(fontName);
                  }}
                  label="Font"
                >
                  {customizer.fonts.map((font: {id: string, name: string}) => <MenuItem key={`font-${font.id}`} value={font.id}>{font.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Collapse>
        <Collapse
          in={field == 'color1'}
        >
          <SingleColorsPanel
            customizer={customizer}
            color={color1}
            onColorChange={(color: string) => setColor1(color)}
          />
        </Collapse>
        <Collapse
          in={field == 'color2'}
        >
          <SingleColorsPanel
            customizer={customizer}
            color={color2}
            onColorChange={(color: string) => setColor2(color)}
          />
        </Collapse>
      </div>
    </div>
  );
}

function CustomizerPanel({customizer, design, onDesignChanged, onResetPressed, className}: any) {
  const classes = useStyles();
  const panelOptions: any = {
    teamName: "Team Name",
    colors: "Colors",
    pattern: "Pattern",
    text: "Text",
    logo: "Logo"
  };

  if (design.template_data && design.template_data.laces && design.template_data.laces.length) {
    panelOptions['laces'] = design.template_data.label_laces;
    console.log(design.template_data)
  }

  const panelOptionKeys = Object.keys(panelOptions);
  const [showPanelSelector, setShowPanelSelector] = useState(false);
  const [showPanelContent, setShowPanelContent] = useState(true);
  const [selectedPanel, setSelectedPanel] = useState('teamName');
  
  let currentIndex = 0;
  panelOptionKeys.forEach((key: string, i: number) => {
    if (key == selectedPanel) {
      currentIndex = i;
    }
  });
  
  return (
    <div className={`${classes.root} ${className ? className : ''}`}>
      <Collapse in={showPanelSelector}>
        <Paper className={classes.panelSelectorContainer}>
          <List component="nav" aria-label="main mailbox folders">
            {panelOptionKeys.map((key: string, i: number) => 
              <ListItem key={`panel-selector-${key}`} button onClick={() => {
                setSelectedPanel(key);
                setShowPanelSelector(false);
                setShowPanelContent(true);
              }}>
                <ListItemText primary={panelOptions[key]} />
              </ListItem>
            )}
          </List>
          <Divider />
          <List component="nav" aria-label="secondary mailbox folders">
            <ListItem button onClick={() => {
              setShowPanelSelector(false);
              onResetPressed();
            }}>
              <ListItemIcon>
                <ReplayIcon />
              </ListItemIcon>
              <ListItemText primary="Start Over" />
            </ListItem>
          </List>
        </Paper>
      </Collapse>

      <div className={classes.panelSwitcherParentContainer}>
        <div>
          <Paper className={classes.panelSwitcherContainer}>
            <ButtonBase className={classes.displayBlock} onClick={() => setShowPanelSelector(!showPanelSelector)}>
              <div className={classes.panelSwitcherSelectedContainer}>
              <Typography className={classes.panelSwitcherSelectedIndexContainer}>{currentIndex + 1}/{panelOptionKeys.length}</Typography>
              <Typography className={classes.panelSwitcherSelectedPanelNameContainer}>{panelOptions[selectedPanel]}</Typography>
              </div>
            </ButtonBase>
            <div className={classes.panelSwitcherButtonsContainer}>
              <div>
                <IconButton
                  onClick={() => setShowPanelSelector(!showPanelSelector)}
                >
                  <MenuIcon />
                </IconButton>
              </div>
              <div>
                <IconButton onClick={() => {
                  panelOptionKeys.forEach((key: string, i: number) => {
                    let nextPanelIndex = currentIndex - 1;
                    if (nextPanelIndex < 0) nextPanelIndex = panelOptionKeys.length - 1;
                    setSelectedPanel(panelOptionKeys[nextPanelIndex]);
                    setShowPanelContent(true);
                  });
                }}>
                  <NavigateBeforeIcon />
                </IconButton>
              </div>
              <div>
                <IconButton onClick={() => {
                  panelOptionKeys.forEach((key: string, i: number) => {
                    let nextPanelIndex = currentIndex + 1;
                    if (nextPanelIndex > (panelOptionKeys.length - 1)) nextPanelIndex = 0;
                    setSelectedPanel(panelOptionKeys[nextPanelIndex]);
                    setShowPanelContent(true);
                  })
                }}>
                  <NavigateNextIcon />
                </IconButton>
              </div>
            </div>
          </Paper>
        </div>
        <Paper className={classes.panelToggleContainer}>
          <IconButton onClick={() => setShowPanelContent(!showPanelContent)}>
            {showPanelContent && <ExpandMoreIcon />}
            {!showPanelContent && <ExpandLessIcon />}
          </IconButton>
        </Paper>
      </div>
      <div className={classes.panelContainer}>
        <Collapse in={showPanelContent && (selectedPanel == 'teamName')}>
          <TeamNamePanel
            customizer={customizer}
            design={design}
            onDesignChanged={(design: any) => {
              onDesignChanged(design);
            }}
          />
        </Collapse>
        <Collapse in={showPanelContent && (selectedPanel == 'pattern')}>
          <PatternPanel
            customizer={customizer}
            design={design}
            onDesignChanged={(design: any) => {
              onDesignChanged(design);
            }}
          />
        </Collapse>
        <Collapse in={showPanelContent && (selectedPanel == 'colors')}>
          <ColorsPanel
            customizer={customizer}
            design={design}
            onDesignChanged={(design: any) => {
              onDesignChanged(design);
            }}
          />
        </Collapse>
        <Collapse in={showPanelContent && (selectedPanel == 'logo')}>
          <LogoPanel
            customizer={customizer}
            design={design}
            onDesignChanged={(design: any) => {
              onDesignChanged(design);
            }}
          />
        </Collapse>
        <Collapse in={showPanelContent && (selectedPanel == 'text')}>
          <TextPanel
            customizer={customizer}
            design={design}
            onDesignChanged={(design: any) => {
              onDesignChanged(design);
            }}
          />
        </Collapse>
        <Collapse in={showPanelContent && (selectedPanel == 'laces')}>
          <LacesPanel
            customizer={customizer}
            design={design}
            onDesignChanged={(design: any) => {
              onDesignChanged(design);
            }}
          />
        </Collapse>
      </div>
    </div>
  );
}

export default CustomizerPanel;
import React, {
  useState,
  useEffect
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Breadcrumbs,
  Link,
  Typography,
  Button,
  ButtonBase,
  IconButton,
  Grid,
  Collapse,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  FormControlLabel,
  Checkbox,
  withStyles,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import { shadeColor } from '../utils/shadeColor';


const useStyles = makeStyles((theme) => ({
  extraSubmitLinks: {
    marginTop: 8,
    marginBottom: 8
  },
  extraEmailsListContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    marginTop: 10,
    marginBottom: 10,
    '& > div': {
      marginRight: 10,
    }
  },
  extraEmailAddButtonContainer: {
    marginTop: 3,
  }
}));

const SaveButton = withStyles((theme) => ({
  root: {
    color: '#ffffff',
    backgroundColor: (window as any).APP_CONFIG ? (window as any).APP_CONFIG.siteColor1 : '#fd6422',
    '&:hover': {
      backgroundColor: shadeColor((window as any).APP_CONFIG ? (window as any).APP_CONFIG.siteColor1 : '#fd6422', 80),
    },
  },
}))(Button);


function validateEmail(email: string) {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}


const SaveForm = ({siteSetting, design, shown, onClose, onSave, isSaving, emailListEnabled}: any) => {
  const classes = useStyles();
  const [hasLogoPermission, setHasLogoPermission] = useState(false);
  const [hasLogoPermissionError, setHasLogoPermissionError] = useState('');
  const [name, setName] = useState((window as any)._customizer_user_name ? (window as any)._customizer_user_name : '');
  const [nameError, setNameError] = useState('');
  const [email, setEmail] = useState((window as any)._customizer_user_email ? (window as any)._customizer_user_email : '');
  const [emailError, setEmailError] = useState('');
  const [teamName, setTeamName] = useState(design.team_name ? design.team_name : ((window as any)._customizer_team_name ? (window as any)._customizer_team_name : design.team_name));
  const [teamNameError, setTeamNameError] = useState('');
  const [notes, setNotes] = useState((window as any)._customizer_user_notes ? (window as any)._customizer_user_notes : '');
  const [addToList, setAddToList] = useState(true);
  const [extraRecipients, setExtraRecipients] = useState([] as string[]);
  const [showExtraRecipientForm, setShowExtraRecipientForm] = useState(false);
  const [extraRecipientFieldContent, setExtraRecipientFieldContent] = useState('');
  const [extraRecipientFieldContentError, setExtraRecipientFieldContentError] = useState('');

  useEffect(() => {
    if (design.team_name && design.team_name != teamName) setTeamName(design.team_name);
  }, [design]);

  const addExtraEmail = () => {
    if (!validateEmail(extraRecipientFieldContent)) {
      setExtraRecipientFieldContentError('Please enter a valid email address.');
      return false;
    }

    let recipients = Object.assign([], extraRecipients);
    recipients.push(extraRecipientFieldContent)
    setExtraRecipients(recipients);
    setShowExtraRecipientForm(false);
    setExtraRecipientFieldContent('');
    setExtraRecipientFieldContentError('');
    return true;
  }

  const doSave = (e: any) => {
    if (e && e.preventDefault) e.preventDefault();
    
    let hasError = false;
    if (!name) {
      setNameError('Please enter your name.');
      hasError = true;
    }
    else {
      setNameError('');
    }

    if (!teamName) {
      setTeamNameError('Please enter your team name.');
      hasError = true;
    }
    else {
      setTeamNameError('');
    }

    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      hasError = true;
    }
    else {
      setEmailError('');
    }

    if (showExtraRecipientForm && extraRecipientFieldContent) {
      setExtraRecipientFieldContentError('Press "Confirm" or dismiss this field before you save your design.')
      hasError = true;
    }

    if (!hasLogoPermission) {
      setHasLogoPermissionError('This field is required');
      hasError = true;
    }
    else {
      setHasLogoPermissionError('');
    }

    if (hasError) return;
    
    const designData: any = {
      team_name: teamName,
      notes: notes,
      player_name: design.player_name ? design.player_name : 'player',
      player_number: design.player_number === '' ?  '97' : design.player_number,
      template: design.template,
      color_1: design.color_1,
      color_2: design.color_2,
      color_3: design.color_3,
      color_4: design.color_4,
      color_5: design.color_5,
      player_name_font: design.player_name_font_id,
      player_number_font: design.player_number_font_id,
      text_color: design.text_color,
      text_color_outline: design.text_color_outline,
      customer_name: name,
      customer_email: email,
      extra_recipients: extraRecipients,
    };

    if (addToList) designData.customer_add_to_list = true;

    if (design.lace) designData.lace = design.lace;
    if (design.logo_file) designData.logo = design.logo_file;
    if (design.logo_left_shoulder_file) designData.logo_left_shoulder = design.logo_left_shoulder_file;
    if (design.logo_right_shoulder_file) designData.logo_right_shoulder = design.logo_right_shoulder_file;

    let canvas = document.getElementById('design_generator') as HTMLCanvasElement;
    canvas.toBlob((image) => {
      designData.rendered_image = image;
      onSave(designData);
      (window as any)._customizer_user_name = name;
      (window as any)._customizer_user_notes = notes;
      (window as any)._customizer_user_email = email;
      (window as any)._customizer_team_name = teamName;
    }, 'image/jpeg');

  }
  
  return (
    <>
      <DialogTitle id="save-dialog-title">Submit Your Design</DialogTitle>
      <DialogContent>

        <DialogContentText>
          {siteSetting.submit_message ? siteSetting.submit_message : 'Email your custom design to yourself and share with friends'}
        </DialogContentText>
        <form onSubmit={doSave}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Your Name"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e: any) => setName(e.target.value.substring(0, 255))}
            error={Boolean(nameError)}
            helperText={nameError}
          />
          <TextField
            margin="dense"
            id="email"
            label="Your Email"
            type="email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e: any) => setEmail(e.target.value.substring(0, 255))}
            error={Boolean(emailError)}
            helperText={emailError}
          />
          <TextField
            margin="dense"
            id="name"
            label="Your Team Name"
            variant="outlined"
            fullWidth
            value={teamName}
            onChange={(e: any) => setTeamName(e.target.value.substring(0, 255))}
            error={Boolean(teamNameError)}
            helperText={teamNameError}
          />
          <Button type="submit" style={{display: 'none'}}>Save</Button>
        </form>

        <div className={classes.extraSubmitLinks} dangerouslySetInnerHTML={{'__html': siteSetting.extra_submit_links}}>

        </div>

        <div className={classes.extraEmailsListContainer}>
          {extraRecipients.map((email: string, i: number) => (
            <Chip
              key={`extra-recipient-list-item-${i}`}
              label={email}
              onDelete={() => {
                let recipients = Object.assign([], extraRecipients);
                recipients.forEach((item, index) => {
                  if (item === email) recipients.splice(index,1);
                });
                setExtraRecipients(recipients);
              }}
            />
          ))}
        </div>

        <Collapse in={showExtraRecipientForm}>
          <form onSubmit={(e) => {
            e.preventDefault();
            addExtraEmail();
          }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  margin="dense"
                  id="another-recipient-email"
                  label="Another Recipient's Email"
                  type="email"
                  variant="outlined"
                  fullWidth
                  value={extraRecipientFieldContent}
                  onChange={(e: any) => setExtraRecipientFieldContent(e.target.value.substring(0, 255))}
                  error={Boolean(extraRecipientFieldContentError)}
                  helperText={extraRecipientFieldContentError}
                />
              </Grid>
              <Grid item xs={6}>
                <div className={classes.extraEmailAddButtonContainer}>
                  <SaveButton
                    startIcon={<CheckIcon />}
                    color="primary"
                    type="submit"
                  >Confirm</SaveButton>
                  <IconButton
                    onClick={() => setShowExtraRecipientForm(false)}
                  ><CloseIcon /></IconButton>
                </div>
              </Grid>
            </Grid>
          </form>
        </Collapse>
        <Collapse in={!showExtraRecipientForm}>
          <Button
            startIcon={<AddIcon />}
            onClick={() => setShowExtraRecipientForm(true)}
          >Add Another Recipient</Button>
        </Collapse>

        <FormControlLabel
          control={
            <Checkbox
              name="hasLogoPermission"
              color="primary"
              checked={hasLogoPermission}
              onChange={(e: any) => setHasLogoPermission(e.target.checked)}
            />
          }
          label="I have permission to use the logo — it's not from a pro/ncaa team  "
        />
        {!!hasLogoPermissionError &&
        <div
          className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense"
          style={{
            marginTop: 0,
            marginBottom: 12,
            color: '#f44336',
            marginLeft: '14px',
            marginRight: '14px',
            textAlign: 'left',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            lineHeight: 1.66,
            letterSpacing: '0.03333em',
            fontSize: '.75rem',
          }}
        >{hasLogoPermissionError}</div>
        }

        {!!emailListEnabled &&
        <FormControlLabel
          control={
            <Checkbox
              name="addToList"
              color="primary"
              checked={addToList}
              onChange={(e: any) => setAddToList(e.target.checked)}
            />
          }
          label="Add me to the 'Chirp Sesh' daily hockey newsletter"
        />}

        <DialogContentText>
        Keep an eye on your inbox. Our team will email you shortly. We're pumped to bring this design to life! 
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <SaveButton
          color="primary"
          onClick={doSave}
          disabled={isSaving}
          id="customizer-submit"
        >
          {isSaving ? "Loading..." : "Submit"}
        </SaveButton>
      </DialogActions>
    </>
  )
};


export default ({siteSetting, design, shown, onClose, onSave, isSaving, emailListEnabled}: any) => {
  const classes = useStyles();

  return (
    <Dialog onClose={onClose} aria-labelledby="save-dialog-title" open={shown}>
      <SaveForm siteSetting={siteSetting} design={design} onClose={onClose} onSave={onSave} isSaving={isSaving} emailListEnabled={emailListEnabled} />
    </Dialog>
  )
}

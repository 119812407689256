import React, {
  useState,
  useEffect
} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  Link as RouterLink,
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Breadcrumbs,
  Link,
  Typography,
  Button,
  ButtonBase,
  IconButton,
  Collapse,
  Hidden,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Tooltip,
  FormControlLabel,
  Checkbox,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReplayIcon from '@material-ui/icons/Replay';
import ShareIcon from '@material-ui/icons/Share';
import InfoIcon from '@material-ui/icons/Info';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import { shadeColor } from '../utils/shadeColor';

import {
  useCustomizerData
} from '../data/CustomizerDataAPI';

import generateFontsCSS from '../utils/generateFontsCSS';

import RenderJersey from '../components/RenderJersey';
import CustomizerPanel from '../components/CustomizerPanel';
import SaveDialog from '../components/SaveDialog';
import ShareDialog from '../components/ShareDialog';
import InfoDialog from '../components/InfoDialog';

import mlsLogo512 from '../img/logo512.png';
let logo512 = ((window as any).APP_CONFIG && (window as any).APP_CONFIG.siteLogo) ? (window as any).APP_CONFIG.siteLogo : mlsLogo512;

const useStyles = makeStyles((theme) => ({
  root: {

  },
  loadingContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 400,
  },
  loadingLogo: {
    display: 'block',
    marginTop: 20,
    marginBottom: 20
  },
  loadingText: {
    width: 200,
    textAlign: 'center',
    opacity: '0.4',
    animationName: '$blinker',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount:'infinite',
  },
  errorText: {
    textAlign: 'center',
  },
  '@keyframes blinker': {
    from: {opacity: 1},
    to: {opacity: 0}
  },
  topBanner: {
    position: 'relative',
    backgroundColor: (window as any).APP_CONFIG ? (window as any).APP_CONFIG.siteColor1 : '#fd6422',
    padding: 10
  },
  topBannerText: {
    textAlign: 'center',
    color: '#ffffff',
    fontWeight: 600,
  },
  topBannerCloseBtn: {
    position: 'absolute',
    top: 5,
    right: 10,
    color: '#ffffff',
  },
  headerContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: "solid 1px rgb(238,238,238)",
    backgroudColor: '#fff',
  },
  headerLogoContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'left',
    padding: 10,
  },
  headerLogo: {
    
  },
  headerLogoText: {
    marginLeft: 10,
    paddingLeft: 10,
    borderLeft: "solid 1px #444",
    fontWeight: 600,
    color: '#444'
  },
  headerMenuContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  headerMenuButtonContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    borderLeft: "solid 1px rgb(238,238,238)",
    height: 69,
    lineHeight: '69px',
  },
  headerMenuShareButtonContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    height: 69,
    lineHeight: '69px',
  },
  greyText: {
    color: '#adadad',
  },
  customizerPanel: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
  rightPriceLabelContainer: {
    position: 'absolute',
    top: 250,
    right: 0,
    padding: '5px',
    paddingRight: '10px',
    background: 'rgba(0,0,0,.333)',
    color: '#fff',
  }
}));


function LoadingComponent() {
  const classes = useStyles();
  let w = 200;
  let h = (w/logo512.width) * logo512.height;

  return (
    <div className={classes.root}>
      <div className={classes.loadingContainer}>
        <img src={logo512} alt="" width={w} height={h} className={classes.loadingLogo} />
        <Typography className={classes.loadingText}>LOADING</Typography>
      </div>
    </div>
  );  
}


function LoadingErrorComponent() {
  const classes = useStyles();
  let w = 200;
  let h = (w/logo512.width) * logo512.height;
  
  return (
    <div className={classes.root}>
      <div className={classes.loadingContainer}>
        <img src={logo512} alt="" width={w} height={h} className={classes.loadingLogo} />
        <Typography className={classes.errorText}>Something went wrong. Please try again.</Typography>
        <Button href="" startIcon={<ReplayIcon />}>Reload</Button>
      </div>
    </div>
  );  
}


function TopBanner({siteSetting, visible, onHide}: any) {
  const classes = useStyles();

  if (!siteSetting) return null;
  return (
    <Collapse
        in={visible && siteSetting && Boolean(siteSetting.top_banner_message_html)}
      >
        <div className={classes.topBanner}>
          <Hidden smDown>
            <div className={`MuiTypography-root MuiTypography-body1 ${classes.topBannerText}`}><div dangerouslySetInnerHTML={{'__html': siteSetting.top_banner_message_html}} /></div>
          </Hidden>
          <Hidden mdUp>
            <Typography className={classes.topBannerText} style={{marginRight: '30px', fontSize: '.7rem'}}><div dangerouslySetInnerHTML={{'__html': siteSetting.top_banner_message_html}} /></Typography>
          </Hidden>
          <IconButton aria-label="close" size="small" className={classes.topBannerCloseBtn} onClick={() => onHide()}>
            <CloseIcon />
          </IconButton>
        </div>
      </Collapse>
  );  
}

const SaveButton = withStyles((theme) => ({
  root: {
    color: '#ffffff',
    backgroundColor: (window as any).APP_CONFIG ? (window as any).APP_CONFIG.siteColor1 : '#fd6422',
    '&:hover': {
      backgroundColor: shadeColor((window as any).APP_CONFIG ? (window as any).APP_CONFIG.siteColor1 : '#fd6422', 80),
    },
  },
}))(Button);

function TopHeader({siteSetting, onSavePressed, onSharePressed, onResetPressed, onInfoPressed, onPricePressed}: any) {
  const classes = useStyles();

  let h = 48;
  let w = (48/logo512.height) * logo512.width;

  let topUrl = 'http://mensleaguesweaters.com/';
  if (siteSetting.top_url) topUrl = siteSetting.top_url;

  if (!siteSetting) return null;
  return (
    <div className={classes.headerContainer}>
      <div className={classes.headerLogoContainer}>
        <ButtonBase onClick={() => window.open(topUrl)}>
          <img src={logo512} alt="" width={w} height={h} className={classes.headerLogo} />
        </ButtonBase>
        {!siteSetting.compact_app_header &&
        <ButtonBase onClick={() => window.open(topUrl)}>
          <Typography className={classes.headerLogoText}>{(window as any).APP_CONFIG ? (window as any).APP_CONFIG.siteName : "Men's League Sweaters"}</Typography>
        </ButtonBase>}
      </div>
      <div className={classes.headerMenuContainer}>
        <Hidden smDown>
          {!!siteSetting.price_content &&
          <div className={classes.headerMenuShareButtonContainer}>
            <Tooltip title="Price">
              <IconButton
                aria-label="Price"
                size="small" 
                className={classes.greyText}
                onClick={onPricePressed}
              ><MonetizationOnIcon /></IconButton>
            </Tooltip>
          </div>}
          {!!siteSetting.info_content &&
          <div className={classes.headerMenuButtonContainer}>
            <Tooltip title="How to order">
              <IconButton
                aria-label="How to order"
                size="small" 
                className={classes.greyText}
                onClick={onInfoPressed}
              ><InfoIcon /></IconButton>
            </Tooltip>
          </div>}
          <div className={classes.headerMenuButtonContainer}>
            <Tooltip title="Start Over">
              <IconButton
                aria-label="Start Over"
                size="small" 
                className={classes.greyText}
                onClick={() => onResetPressed()}
              ><ReplayIcon /></IconButton>
            </Tooltip>
          </div>
          <div className={classes.headerMenuButtonContainer}>
            <SaveButton
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              onClick={() => {
                if (onSavePressed) onSavePressed();
              }}
            >SUBMIT</SaveButton>
          </div>  
        </Hidden>
        <Hidden mdUp>
          <div className={classes.headerMenuShareButtonContainer}>
            <Tooltip title="How to order">
              <IconButton
                aria-label="How to order"
                size="small" 
                className={classes.greyText}
                onClick={onInfoPressed}
              ><InfoIcon /></IconButton>
            </Tooltip>
          </div>
          <div className={classes.headerMenuButtonContainer}>
            <SaveButton
              variant="contained"
              color="primary"
              size="small"
              disableElevation
              onClick={() => {
                if (onSavePressed) onSavePressed();
              }}
            >SUBMIT</SaveButton>
          </div>  
        </Hidden>
      </div>
    </div>
  );  
}


function PriceLabel({siteSetting}: any) {
  const classes = useStyles();

  if (!siteSetting.right_pricing_label_html) return null;
  return (
    <div className={classes.rightPriceLabelContainer} dangerouslySetInnerHTML={{'__html': siteSetting.right_pricing_label_html}} />
  );
}


function HomePage() {
  const classes = useStyles();
  const { slug } = useParams<{slug?: string}>();
  let categorySlug = slug ? slug : '';
  const [design, setDesign] = useState<JerseyDesign>({
    team_name: '',
    player_name: 'player',
    player_number: '97',
    template: null,
    renderer: 'v1',
    template_data: {},
    color_1: '#000000',
    color_2: '#000000',
    color_3: '#000000',
    color_4: '#000000',
    color_5: '#000000',
    player_name_font_name: '',
    player_number_font_name: '',
    player_name_font_id: null,
    player_number_font_id: null,
    text_color: '#FFFFFF',
    text_color_outline: '#000000',
    logo_url: '',
    logo_file: null,
    logo_left_shoulder_url: '',
    logo_left_shoulder_file: null,
    logo_right_shoulder_url: '',
    logo_right_shoulder_file: null,
    logo_extra_1_url: '',
    logo_extra_2_url: '',
    number_of_logos: 3,
    label_logo_1: '',
    label_logo_2: '',
    label_logo_3: '',
    label_laces: '',
    rendered_image_url: null,
    rendered_image_file: null,
    customer_name: '',
    customer_email: '',
    extra_recipients: []
  } as any);
  const [showTopBanner, setShowTopBanner] = useState(true);
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [showPriceDialog, setShowPriceDialog] = useState(false);
  const [customizer, customizerLoading, customizerError, saveCustomizerDesign, saveDesignLoading] = useCustomizerData(`?category=${categorySlug}`);

  useEffect(() => {
    if (customizer && customizer.fonts) {
      generateFontsCSS(customizer.fonts);
    }

    if (customizer && customizer.templates && customizer.templates.length) {
      let designClone = Object.assign({}, design);
      designClone.template_data = customizer.templates[0];
      designClone.template = customizer.templates[0].id;
      designClone.renderer = customizer.templates[0].renderer;
      designClone.color_1 = customizer.templates[0].default_color_1;
      designClone.color_2 = customizer.templates[0].default_color_2;
      designClone.color_3 = customizer.templates[0].default_color_3;
      designClone.color_4 = customizer.templates[0].default_color_4;
      designClone.color_5 = customizer.templates[0].default_color_5;
      designClone.player_name_font_id = customizer.templates[0].player_name_font_id;
      designClone.player_number_font_id = customizer.templates[0].player_number_font_id;
      designClone.player_name_font_name = customizer.templates[0].player_name_font_name;
      designClone.player_number_font_name = customizer.templates[0].player_number_font_name;
      designClone.text_color = customizer.templates[0].text_color;
      designClone.text_color_outline = customizer.templates[0].text_stroke_color;
      designClone.logo_url = customizer.templates[0].logo;
      designClone.logo_position = JSON.parse(JSON.stringify(customizer.templates[0].logo_position));
      designClone.logo_position_dirty = false;
      designClone.logo_left_shoulder_url = customizer.templates[0].logo_left_shoulder;
      designClone.logo_left_shoulder_front_position = JSON.parse(JSON.stringify(customizer.templates[0].logo_left_shoulder_front_position));
      designClone.logo_left_shoulder_back_position = JSON.parse(JSON.stringify(customizer.templates[0].logo_left_shoulder_back_position));
      designClone.logo_left_shoulder_position_dirty = false;
      designClone.logo_right_shoulder_url = customizer.templates[0].logo_right_shoulder;
      designClone.logo_right_shoulder_front_position = JSON.parse(JSON.stringify(customizer.templates[0].logo_right_shoulder_front_position));
      designClone.logo_right_shoulder_back_position = JSON.parse(JSON.stringify(customizer.templates[0].logo_right_shoulder_back_position));
      designClone.logo_right_shoulder_position_dirty = false;
      designClone.logo_extra_1_url = customizer.templates[0].logo_extra_1;
      designClone.logo_extra_1_position = JSON.parse(JSON.stringify(customizer.templates[0].logo_extra_1_position));
      designClone.logo_extra_2_url = customizer.templates[0].logo_extra_2;
      designClone.logo_extra_2_position = JSON.parse(JSON.stringify(customizer.templates[0].logo_extra_2_position));
      designClone.number_of_logos = customizer.templates[0].number_of_logos;
      designClone.label_logo_1 = customizer.templates[0].label_logo_1;
      designClone.label_logo_2 = customizer.templates[0].label_logo_2;
      designClone.label_logo_3 = customizer.templates[0].label_logo_3;
      designClone.label_laces = customizer.templates[0].label_laces;
      designClone.show_player_name_field = customizer.templates[0].show_player_name_field;
      designClone.show_player_number_field = customizer.templates[0].show_player_number_field;
      setDesign(designClone);
      setTimeout(() => {
        // re-render again in case fonts loaded late
        let designClone2 = Object.assign({}, designClone);
        setDesign(designClone2);
      }, 1000);
    }

  }, [customizer])
  
  useEffect(() => {
    (window as any)._customizer_is_editor_dirty = true;
  }, [design]);
  
  if (customizerLoading) return (<LoadingComponent />);
  if (!customizerLoading && customizerError) {
    return (<LoadingErrorComponent />);
  }

  const onResetPressed = () => {
    setShowResetDialog(!showResetDialog);
  }

  if (!customizer) return (<></>);

  return (
    <div className={classes.root}>
      <TopBanner
        siteSetting={customizer.site_setting}
        visible={showTopBanner}
        onHide={() => setShowTopBanner(false)}
      />
      <TopHeader
        siteSetting={customizer.site_setting}
        onSavePressed={() => setShowSaveDialog(true)}
        onSharePressed={() => setShowShareDialog(true)}
        onInfoPressed={() => setShowInfoDialog(true)}
        onPricePressed={() => setShowPriceDialog(true)}
        onResetPressed={onResetPressed}
      />
      <RenderJersey
        siteSetting={customizer.site_setting}
        topBannerShown={showTopBanner}
        design={design}
      />
      <PriceLabel
        siteSetting={customizer.site_setting}
      />
      <CustomizerPanel
        customizer={customizer}
        design={design}
        onDesignChanged={(design: any) => setDesign(design)}
        onResetPressed={onResetPressed}
        className={classes.customizerPanel}
      />

      <SaveDialog
        siteSetting={customizer.site_setting}
        design={design}
        shown={showSaveDialog}
        onClose={() => setShowSaveDialog(false)}
        onSave={async (saveData: any) => {
          const [success, result, error] = await saveCustomizerDesign(saveData);
          
          if (success) {
            (window as any)._customizer_design_id = result.id;
            (window as any)._customizer_is_editor_dirty = false;
            setShowSaveDialog(false);
            if (!showShareDialog) {
              if (customizer && customizer.site_setting && customizer.site_setting.save_redirect) {
                const designId = (window as any)._customizer_is_editor_dirty ? undefined : (window as any)._customizer_design_id;
                const shareUrl = `${window.location.origin}/design/${designId}/`;
                const redirectUrl = `${customizer.site_setting.save_redirect}?share_url=${shareUrl}`;
                window.location.assign(redirectUrl);
              }
              else {
                setShowShareDialog(true);
              }
            }
          }
          else {
            console.log('error saving data:', error.status, error.message);
            if (error.status == 1200) {
              window.alert('Error saving your design. Please check your internet connection and try again.');
            }
            else if (error.status) {
              window.alert(`Error saving your design. Please try again. Contact us if the issue persist. [${error.status}]`);
            }
            else {
              window.alert(`Error saving your design. Please try again. Contact us if the issue persist. [${error.message}]`);
            }
          }
        }}
        isSaving={saveDesignLoading}
        emailListEnabled={customizer.site_setting ? customizer.site_setting.mail_list_enabled : false}
      />

      <ShareDialog
        shown={showShareDialog}
        onClose={() => setShowShareDialog(false)}
        onSave={() => {
          setShowSaveDialog(true);
        }}
      />

      <InfoDialog
        content={customizer.site_setting.info_content}
        shown={showInfoDialog}
        onClose={() => setShowInfoDialog(false)}
      />

      <InfoDialog
        content={customizer.site_setting.price_content}
        shown={showPriceDialog}
        onClose={() => setShowPriceDialog(false)}
      />

      <Dialog
        open={showResetDialog}
        onClose={() => setShowResetDialog(false)}
        aria-labelledby="confirm-reset-dialog-title"
        aria-describedby="confirm-reset-dialog-description"
      >
        <DialogTitle id="confirm-reset-dialog-title">Start Again From Scratch?</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-reset-dialog-description">
            Do you want to start over? All unsaved changes will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowResetDialog(false)} color="primary">
            Cancel
          </Button>
          <SaveButton variant="contained" onClick={() => window.location.reload()} color="primary" startIcon={<ReplayIcon />} >
            Start Over
          </SaveButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default HomePage;
import React, {
  useState,
  useEffect
} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Breadcrumbs,
  Link,
  Typography,
  Button,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {

  },
  pageNotFoundContainer: {
    padding: 10,
  },
  pageNotFoundTitle: {
    marginBottom: 20
  },
}));
  

function NotFoundPage() {
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.pageNotFoundContainer}>
        <Typography variant="h5" className={classes.pageNotFoundTitle}>404 Page Not Found</Typography>
        <Button variant="contained" color="primary" to="/" component={RouterLink}>Return to Home Page</Button>
      </Paper>
    </>
  );
}

export default NotFoundPage;
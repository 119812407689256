
import mlsLogo512 from '../img/logo512.png';
import sampleImage from '../img/render.jpg';

interface CanvasOption {
  main: string;
  part1: string;
  part2: string;
  part3: string;
}

let logo512 = ((window as any).APP_CONFIG && (window as any).APP_CONFIG.siteLogo) ? (window as any).APP_CONFIG.siteLogo : mlsLogo512;

const defaultImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=';
const palleteColors = ["#ff00ff", "#ff0000", "#00ff00", "#0000ff", "#00ffff" ];
let layersImgCached: any = {};

function componentToHex(c: number) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r: number, g: number, b: number) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return {
    r: result ? parseInt(result[1], 16) : 255,
    g: result ? parseInt(result[2], 16) : 255,
    b: result ? parseInt(result[3], 16): 255
  };
}

function hexToRgbAlt(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return {
    r: result ? parseInt(result[1], 16)/255 : 1,
    g: result ? parseInt(result[2], 16)/255 : 1,
    b: result ? parseInt(result[3], 16)/255: 1
  };
}

interface ColorsOption {
  original: { r: number; g: number; b: number; }[],
  current: { r: number; g: number; b: number; }[]
}

let recolorTexture = function (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, colors: ColorsOption) {
  let imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  let data = imgData.data;
  let tolerance = 128;

  if (data && data.length) {
    for (let i = 0; i < data.length; i += 4) {
        const red = data[i + 0];
        const green = data[i + 1];
        const blue = data[i + 2];
        const alpha = data[i + 3];

        let newcolor = null;
        let deltaR = 0;
        let deltaG = 0;
        let deltaB = 0;
        for (let c = 0; c < colors.original.length; c++) {
          let original = colors.original[c];
          let current = colors.current[c];
          if ((red <= (original.r + tolerance)) && (red >= (original.r - tolerance))) {
            if ((green <= (original.g + tolerance)) && (green >= (original.g - tolerance))) {
              if ((blue <= (original.b + tolerance)) && (blue >= (original.b - tolerance))) {
                newcolor = current;
                deltaR = red - original.r;
                deltaG = green - original.g;
                deltaB = blue - original.b;
                break;
              }
            }
          }
        }
        
        // change blueish pixels to the new color
        if (newcolor) {
          data[i + 0] = newcolor.r + (deltaR + deltaG + deltaB) / 3;
          data[i + 1] = newcolor.g + (deltaR + deltaG + deltaB) / 3;
          data[i + 2] = newcolor.b + (deltaR + deltaG + deltaB) / 3;
          data[i + 3] = alpha;
        }
        if (alpha < 100) {
          data[i + 3] = Math.sqrt(alpha); // hide transparent color as they're mostly unused in the template
        }
    }
  }
  ctx.putImageData(imgData, 0, 0);
}

let renderText = (design: JerseyDesign, canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, texts: TextTemplate[]) => {
  texts.forEach((item, i) => {

    var _fillStyle = ctx.fillStyle;
    var _strokeStyle = ctx.strokeStyle;
    var _lineWidth = ctx.lineWidth;
    var _font = ctx.font;
    var _textAlign = ctx.textAlign;

    var fill_color = item.fill_color;
    var stroke_color = item.stroke_color;

    if (fill_color == 'color_1') fill_color = design.color_1;
    if (fill_color == 'color_2') fill_color = design.color_2;
    if (fill_color == 'color_3') fill_color = design.color_3;
    if (fill_color == 'color_4') fill_color = design.color_4;
    if (fill_color == 'color_5') fill_color = design.color_5;
    if (fill_color == 'text_color') fill_color = design.text_color;
    
    if (stroke_color == 'color_1') stroke_color = design.color_1;
    if (stroke_color == 'color_2') stroke_color = design.color_2;
    if (stroke_color == 'color_3') stroke_color = design.color_3;
    if (stroke_color == 'color_4') stroke_color = design.color_4;
    if (stroke_color == 'color_5') stroke_color = design.color_5;
    if (stroke_color == 'text_stroke_color') stroke_color = design.text_color_outline;

    ctx.fillStyle = fill_color;
    ctx.strokeStyle = stroke_color;
    ctx.lineWidth = item.line_width;

    if (item.font_family) {
      ctx.font = item.font_size +"px "+item.font_family;
    }

    var textContent = "";
    if (item.text_type == 'player_number') {
      textContent = design.player_number;
      if (design.player_number_font_name) {
        ctx.font = `${item.font_size}px ${design.player_number_font_name}`;
      }
    }
    if (item.text_type == 'player_name') {
      textContent = design.player_name;
      if (design.player_name_font_name) {
        ctx.font = `${item.font_size}px ${design.player_name_font_name}`;
      }
    }
    if (item.text_type == 'custom' && item.custom_text) {
      textContent = item.custom_text;
    }

    if (item.font_family) {
      ctx.font = item.font_size +"px "+item.font_family;
    }

    ctx.save();

    if (item.text_align) ctx.textAlign = item.text_align;

    ctx.globalCompositeOperation = "source-atop";
    ctx.translate(item.x, item.y);

    if (item.angle) {
      ctx.rotate(item.angle * Math.PI / 180);
    }

    if (fill_color != stroke_color) {
      ctx.fillStyle = fill_color;
      ctx.strokeStyle = stroke_color;
      ctx.strokeText(textContent, 0, 0);
    }

    ctx.fillStyle = fill_color;
    ctx.strokeStyle = stroke_color;
    ctx.fillText(textContent, 0, 0);

    ctx.globalCompositeOperation = "source-over";
    ctx.restore();
    
    ctx.textAlign = _textAlign;
    ctx.fillStyle = _fillStyle;
    ctx.strokeStyle = _strokeStyle;
    ctx.lineWidth = _lineWidth;
    ctx.font = _font;
  });
}


export default async (design: JerseyDesign, canvasOpt: CanvasOption, siteSetting: SiteSetting): Promise<string> => {
  const mainCanvas = document.getElementById(canvasOpt.main) as HTMLCanvasElement;
  if (!siteSetting.show_bottom_logo) {
    mainCanvas.height = 1200;
  }
  else {
    mainCanvas.height = 1500;
  }

  const mainCtx = mainCanvas.getContext("2d");
  if (!mainCanvas || !mainCtx) return defaultImage;

  const part1Canvas = document.getElementById(canvasOpt.part1) as HTMLCanvasElement;
  const part1Ctx = part1Canvas.getContext("2d");
  if (!part1Canvas || !part1Ctx) return defaultImage;

  const part2Canvas = document.getElementById(canvasOpt.part2) as HTMLCanvasElement;
  const part2Ctx = part2Canvas.getContext("2d");
  if (!part2Canvas || !part2Ctx) return defaultImage;

  const part3Canvas = document.getElementById(canvasOpt.part3) as HTMLCanvasElement;
  const part3Ctx = part3Canvas.getContext("2d");
  if (!part3Canvas || !part3Ctx) return defaultImage;


  return new Promise((resolve, reject) => {

    const startIfAllLoaded = () => {
      loadingCounter -= 1;
      if (loadingCounter == 0) {
        start();
      }
    }

    const start = () => {
      mainCtx.clearRect(0, 0, mainCanvas.width, mainCanvas.height);
      part1Ctx.clearRect(0, 0, part1Canvas.width, part1Canvas.height);
      part2Ctx.clearRect(0, 0, part2Canvas.width, part2Canvas.height);
      part3Ctx.clearRect(0, 0, part3Canvas.width, part3Canvas.height);

      // render base layer
      if (design.template_data.part_1_template) part1Ctx.drawImage(layersImgCached[design.template_data.part_1_template], 0, 0, 1024, 1024);
      if (design.template_data.part_2_template) part2Ctx.drawImage(layersImgCached[design.template_data.part_2_template], 0, 0, 1024, 1024);
      if (design.template_data.part_3_template) part3Ctx.drawImage(layersImgCached[design.template_data.part_3_template], 0, 0, 1024, 1024);

      // render laces
      if (design.lace) { 
        part1Ctx.save();
        part1Ctx.globalCompositeOperation = "source-atop";
        design.template_data.laces.forEach((lace) => {
          if (lace.id === design.lace) {
            console.log(lace, lace.template_position.x,
              lace.template_position.y,
              lace.template_position.w, (lace.template_position.w / layersImgCached[lace.template_image].width) * layersImgCached[lace.template_image].height);
            part1Ctx.drawImage(
              layersImgCached[lace.template_image],
              lace.template_position.x,
              lace.template_position.y,
              lace.template_position.w,
              (lace.template_position.w / layersImgCached[lace.template_image].width) * layersImgCached[lace.template_image].height
            );
          }
        });
    
        part1Ctx.globalCompositeOperation = "source-over";
        part1Ctx.restore();
      }

      // recolor base layer
      let colors: ColorsOption = {
        original: [
          hexToRgb(palleteColors[0]),
          hexToRgb(palleteColors[1]),
          hexToRgb(palleteColors[2]),
          hexToRgb(palleteColors[3]),
          hexToRgb(palleteColors[4]),
        ],
        current: [
          hexToRgb(design.color_1),
          hexToRgb(design.color_2),
          hexToRgb(design.color_3),
          hexToRgb(design.color_4),
          hexToRgb(design.color_5),
        ],
      }

      recolorTexture(part1Canvas, part1Ctx, colors);
      recolorTexture(part2Canvas, part2Ctx, colors);
      recolorTexture(part3Canvas, part3Ctx, colors);

      renderText(design, part1Canvas, part1Ctx, design.template_data.part_1_template_text);
      renderText(design, part2Canvas, part2Ctx, design.template_data.part_2_template_text);
      renderText(design, part3Canvas, part3Ctx, design.template_data.part_3_template_text);

      // render logo
      if (design.logo_url) { 
        part1Ctx.save();
        part1Ctx.globalCompositeOperation = "source-atop";

        part1Ctx.drawImage(
          layersImgCached[design.logo_url],
          design.logo_position.x,
          design.logo_position.y,
          design.logo_position.w,
          (design.logo_position.w / layersImgCached[design.logo_url].width) * layersImgCached[design.logo_url].height
        );

        part1Ctx.globalCompositeOperation = "source-over";
        part1Ctx.restore();
      }

      // render left shoulder logo
      if (design.logo_left_shoulder_url) { 

        let width = design.logo_left_shoulder_front_position.w;
        let height = (design.logo_left_shoulder_front_position.w / layersImgCached[design.logo_left_shoulder_url].width) * layersImgCached[design.logo_left_shoulder_url].height;

        part1Ctx.save();
        part1Ctx.globalCompositeOperation = "source-atop";
        part1Ctx.translate(design.logo_left_shoulder_front_position.x + (width/2), design.logo_left_shoulder_front_position.y + (height / 2));
        if (design.logo_left_shoulder_front_position.r) {
          part1Ctx.rotate(design.logo_left_shoulder_front_position.r * (Math.PI/180));
        }

        part1Ctx.drawImage(
          layersImgCached[design.logo_left_shoulder_url],
          -(width/2),
          -(height/2),
          width,
          height
        );

        part1Ctx.globalCompositeOperation = "source-over";
        part1Ctx.restore();

        part2Ctx.save();

        width = design.logo_left_shoulder_back_position.w;
        height = (design.logo_left_shoulder_back_position.w / layersImgCached[design.logo_left_shoulder_url].width) * layersImgCached[design.logo_left_shoulder_url].height;

        part2Ctx.globalCompositeOperation = "source-atop";
        part2Ctx.translate(design.logo_left_shoulder_back_position.x + (width/2), design.logo_left_shoulder_back_position.y + (height / 2));
        if (design.logo_left_shoulder_back_position.r) {
          part2Ctx.rotate(design.logo_left_shoulder_back_position.r * (Math.PI/180));
        }
    
        part2Ctx.drawImage(
          layersImgCached[design.logo_left_shoulder_url],
          -(width/2),
          -(height/2),
          width,
          height
        );

        part2Ctx.globalCompositeOperation = "source-over";
        part2Ctx.restore();
      }

      // render right shoulder logo
      if (design.logo_right_shoulder_url) { 
        let width = design.logo_right_shoulder_front_position.w;
        let height = (design.logo_right_shoulder_front_position.w / layersImgCached[design.logo_right_shoulder_url].width) * layersImgCached[design.logo_right_shoulder_url].height;

        part1Ctx.save();
        part1Ctx.globalCompositeOperation = "source-atop";
        part1Ctx.translate(design.logo_right_shoulder_front_position.x + (width/2), design.logo_right_shoulder_front_position.y + (height / 2));
        if (design.logo_right_shoulder_front_position.r) {
          part1Ctx.rotate(design.logo_right_shoulder_front_position.r * (Math.PI/180));
        }

        part1Ctx.drawImage(
          layersImgCached[design.logo_right_shoulder_url],
          -(width/2),
          -(height/2),
          width,
          height
        );

        part1Ctx.globalCompositeOperation = "source-over";
        part1Ctx.restore();

        part2Ctx.save();

        width = design.logo_right_shoulder_back_position.w;
        height = (design.logo_right_shoulder_back_position.w / layersImgCached[design.logo_right_shoulder_url].width) * layersImgCached[design.logo_right_shoulder_url].height;

        part2Ctx.globalCompositeOperation = "source-atop";
        part2Ctx.translate(design.logo_right_shoulder_back_position.x + (width/2), design.logo_right_shoulder_back_position.y + (height / 2));
        if (design.logo_right_shoulder_back_position.r) {
          part2Ctx.rotate(design.logo_right_shoulder_back_position.r * (Math.PI/180));
        }
    
        part2Ctx.drawImage(
          layersImgCached[design.logo_right_shoulder_url],
          -(width/2),
          -(height/2),
          width,
          height
        );

        part2Ctx.globalCompositeOperation = "source-over";
        part2Ctx.restore();
      }


      // render shading layer
      if (design.template_data.part_1_template_shading) part1Ctx.drawImage(layersImgCached[design.template_data.part_1_template_shading], 0, 0, 1024, 1024);
      if (design.template_data.part_2_template_shading) part2Ctx.drawImage(layersImgCached[design.template_data.part_2_template_shading], 0, 0, 1024, 1024);
      if (design.template_data.part_3_template_shading) part3Ctx.drawImage(layersImgCached[design.template_data.part_3_template_shading], 0, 0, 1024, 1024);
      
      // render final image
      mainCtx.save();
      mainCtx.translate(0, 0);
      mainCtx.rect(0, 0, mainCanvas.width, mainCanvas.height);
      mainCtx.fillStyle = 'white';
      mainCtx.fill();

      if (siteSetting.show_bottom_logo) {
        mainCtx.drawImage(part3Canvas, -200, 214);
        mainCtx.drawImage(part2Canvas, 500, 260);
        mainCtx.drawImage(part1Canvas, 184, 234);
      }
      else {
        mainCtx.drawImage(part3Canvas, -200, 220);
        mainCtx.drawImage(part2Canvas, 500, 220);
        mainCtx.drawImage(part1Canvas, 184, 220);
      }

      let teamNameFontSize = 140 * 0.6;

      mainCtx.fillStyle = '#000000';
      mainCtx.strokeStyle = '#000000';
      mainCtx.lineWidth = 0
      mainCtx.font = (teamNameFontSize) + "px Myriad Pro Black";
      mainCtx.textAlign = 'center';

      let teamName = design.team_name;
      if (!teamName) {
        teamName = "[Team Name]";
      }

      teamName = `${teamName}.`;
      let targetTeamNameWidth = mainCanvas.width - 100;

      let teamNameTextMeasurement = mainCtx.measureText(teamName.toUpperCase());

      if (teamNameTextMeasurement.width < targetTeamNameWidth) {
        do {
          teamNameFontSize += 1;
          mainCtx.font = "normal " + (teamNameFontSize) + "px \"Myriad Pro Black\"";
          teamNameTextMeasurement = mainCtx.measureText(teamName.toUpperCase());
        } while ((teamNameTextMeasurement.width < targetTeamNameWidth) && (teamNameFontSize < 100));
        console.log(teamNameFontSize)
      }
      else {
        do {
          teamNameFontSize -= 1;
          mainCtx.font = "normal " + (teamNameFontSize) + "px \"Myriad Pro Black\"";
          teamNameTextMeasurement = mainCtx.measureText(teamName.toUpperCase());
        } while ((teamNameTextMeasurement.width > targetTeamNameWidth) && (teamNameFontSize > 20));
      }

      mainCtx.translate(mainCanvas.width / 2, 200);
      mainCtx.fillText(teamName.toUpperCase(), 0, -20);

      mainCtx.translate(0, 75);
      if (siteSetting.tag_line) {
        const mlsLogoImage = layersImgCached[logo512];
        if (siteSetting.show_logo_above_tagline && mlsLogoImage) {
          let h = 60;
          let w = (h/mlsLogoImage.height) * mlsLogoImage.width;
          mainCtx.drawImage(mlsLogoImage, -(w/2), -55, w, h);
          mainCtx.font = "bold italic " + (46 * 0.6) + "px \"Helvetica Neue,Helvetica,Roboto,Arial,sans-serif\"";
          mainCtx.fillText(siteSetting.tag_line, 0, 45);
        }
        else {
          mainCtx.font = "bold italic " + (46 * 0.6) + "px \"Helvetica Neue,Helvetica,Roboto,Arial,sans-serif\"";
          mainCtx.fillText(siteSetting.tag_line, 0, 0);
        }
      }
      else {
        const mlsLogoImage = layersImgCached[logo512];
        if (mlsLogoImage) {
          mainCtx.drawImage(mlsLogoImage, -200, -60, 75 * 0.6, 75 * 0.6);
        }

        mainCtx.font = `normal ${42 * 0.6}px "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", "sans-serif"`;
        mainCtx.fillStyle = '#555';
        mainCtx.fillText("Men's League Sweaters", 0, -30);
      }

      if (siteSetting.show_bottom_logo) {
        mainCtx.translate(0, 875);
        const mlsLogoImage = layersImgCached[logo512];
        if (mlsLogoImage) {
          mainCtx.drawImage(mlsLogoImage, -128, 0, 256, 236);
        }

        mainCtx.font = `normal ${32}px "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", "sans-serif"`;
        mainCtx.fillStyle = '#555';
        mainCtx.translate(0, 310);
        mainCtx.fillText(((window as any).APP_CONFIG ? (window as any).APP_CONFIG.renderTagline : "mensleaguesweaters.com").split("").join(' '), 0, 0);
      }
      
      mainCtx.restore();
      const imageData = mainCanvas.toDataURL("image/jpeg")
      resolve(imageData);
    }

    const loadImage = (imageUrl: string) => {
      if (!layersImgCached[imageUrl]) {
        layersImgCached[imageUrl] = new Image();
        layersImgCached[imageUrl].crossOrigin = "anonymous";
        layersImgCached[imageUrl].onload = startIfAllLoaded;
        layersImgCached[imageUrl].src = imageUrl;
        return true;
      }
      return false;
    };

    let loadingCounter = 0;
    if (design.logo_url && loadImage(design.logo_url)) loadingCounter += 1;
    if (design.logo_left_shoulder_url && loadImage(design.logo_left_shoulder_url)) loadingCounter += 1;
    if (design.logo_right_shoulder_url && loadImage(design.logo_right_shoulder_url)) loadingCounter += 1;
    if (logo512 && loadImage(logo512)) loadingCounter += 1;

    if (design.template_data) {
      if (design.template_data.part_1_template && loadImage(design.template_data.part_1_template)) loadingCounter += 1;
      if (design.template_data.part_1_template_shading && loadImage(design.template_data.part_1_template_shading)) loadingCounter += 1;
      if (design.template_data.part_2_template && loadImage(design.template_data.part_2_template)) loadingCounter += 1;
      if (design.template_data.part_2_template_shading && loadImage(design.template_data.part_2_template_shading)) loadingCounter += 1;
      if (design.template_data.part_3_template && loadImage(design.template_data.part_3_template)) loadingCounter += 1;
      if (design.template_data.part_3_template_shading && loadImage(design.template_data.part_3_template_shading)) loadingCounter += 1;
    }

    design.template_data.laces.forEach((lace) => {
      if (lace.id === design.lace) {
        if (lace.template_image && loadImage(lace.template_image)) loadingCounter += 1;
      }
    });

    if (loadingCounter == 0) {
      setTimeout(function() {
        start();
      }, 0);
    }

  });
}
import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Grid,
  Paper,
  Chip,
  ThemeProvider,
} from '@material-ui/core';

import { spring, AnimatedSwitch } from 'react-router-transition';

import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';

import './App.scss';

import packageJson from '../package.json';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#ffffff",
  },
}));

function bounce(val: any) {
  return spring(val, {
    stiffness: 330,
    damping: 35,
  });
}

function App() {

  // child matches will...
  const bounceTransition = {
    // start in a transparent, upscaled state
    atEnter: {
      opacity: 0,
      scale: 100,
    },
    // leave in a transparent, downscaled state
    atLeave: {
      opacity: bounce(0),
      scale: bounce(0),
    },
    // and rest at an opaque, normally-scaled state
    atActive: {
      opacity: bounce(1),
      scale: bounce(0),
    },
  };

  const classes = useStyles();
  
  return (
    <Router>
      <div className={classes.root}>
        <AnimatedSwitch
          atEnter={bounceTransition.atEnter}
          atLeave={bounceTransition.atLeave}
          atActive={bounceTransition.atActive}
          mapStyles={(styles: any) => {
            const stylesheet = { position: 'relative', opacity: styles.opacity, transform: 'none', height: 'auto' };
            if ( (styles.scale !== 0)) {
              stylesheet.transform = `translateX(${styles.scale}%)`;
            }
            if ( (styles.opacity !== 1)) {
              stylesheet.position = 'absolute';
            }
            return stylesheet;
          }}
          className="switch-wrapper"
        >
          <Route exact path={'/jersey2d/'}>
            <HomePage />
          </Route>
          <Route exact path={'/jersey/'}>
            <HomePage />
          </Route>
          <Route exact path={'/:slug/'}>
            <HomePage />
          </Route>
          <Route exact path={'/'}>
            <HomePage />
          </Route>
          <Route>
            <NotFoundPage />
          </Route>
        </AnimatedSwitch>
      </div>
    </Router>
  );
}

export default App;
